<template>
    <font-awesome-icon
        icon="edit"
        size="lg"
        class="icon-button"
        title="Atualizar"
        v-if="$temAcessoView('UTIL-CONSULTORIAS-03') && tipo == 'I'"
        @click="toAtualizar()"
    />
    <Button
        label="Atualizar"
        icon="pi pi-pencil"
        iconPos="left"
        class="p-button"
        v-if="$temAcessoView('UTIL-CONSULTORIAS-03') && tipo == 'B'"
        @click="toAtualizar()"
    />
</template>

<script>
export default {
    props: {
        tipo: {
            type: String,
        },

        consultoria: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$router.push({
                name: 'Utilidades_Consultorias_Atualizar',
                params: {
                    id: this.consultoria.consultoriaId,
                },
            });
        },
    },
};
</script>