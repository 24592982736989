<template>
    <Button label="Nova Consultoria" icon="pi pi-plus" iconPos="left" class="p-button" v-if="$temAcessoView('UTIL-CONSULTORIAS-01')" @click="toInserir()" />
</template>

<script>
export default {
    methods: {
        toInserir() {
            this.$router.push({
                name: 'Utilidades_Consultorias_Inserir',
            });
        },
    },
};
</script>