<template>
    <painel titulo="Consultorias" icone="handshake" :refreshFunction="atualizar" v-show="mostrarListagem">
        <erros-box :fromBus="true"></erros-box>
        <tabela
            :data="consultorias"
            dataKey="consultoriaId"
            :globalFilterFields="[
                'codAtendimento',
                'codigoEmpresa',
                'tipoEmpresa',
                'cpf',
                'inicioAtendimento',
                'finalAtendimento',
                'tituloAtendimento',
                'tipoAtendimento',
                'instrumento',
                'abordagem',
                'competencia',
                'cargaHoraria',
                'tema',
                'cpfResponsavel',
            ]"
            stateKey="dt-utilidades-consultorias"
        >
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <div class="flex">
                            <btn-detalhar :consultoria="slotProps.data"></btn-detalhar>
                            <span class="ml-2"><btn-atualizar :consultoria="slotProps.data" tipo="I"></btn-atualizar></span>
                            <span class="ml-2"
                                ><btn-excluir :consultoria="slotProps.data" tipo="I" @obterListagemAtualizada="obterConsultorias()"></btn-excluir
                            ></span>
                        </div>
                    </template>
                </Column>
                <Column field="codAtendimento" header="Código" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codAtendimento }}
                    </template>
                </Column>
                <Column field="cpf" header="CPF do Cliente" :sortable="true">
                    <template #body="slotProps">
                        {{ $toCpfCnpj(slotProps.data.cpf) }}
                    </template>
                </Column>
                <Column field="tipoEmpresa" header="Tipo do Cliente" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tipoEmpresa }}
                    </template>
                </Column>
                <Column field="codigoEmpresa" header="Código do Cliente" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.codigoEmpresa }}
                    </template>
                </Column>
                <Column field="inicioAtendimento" header="Início do Atend." :sortable="true">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.inicioAtendimento, 'DD/MM/YYYY HH:mm') }}
                    </template>
                </Column>
                <Column field="tituloAtendimento" header="Título" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tituloAtendimento }}
                    </template>
                </Column>
                <Column field="tipoAtendimento" header="Tipo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tipoAtendimento }}
                    </template>
                </Column>
                <Column field="instrumento" header="Instrumento" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.instrumento }}
                    </template>
                </Column>
                <Column field="abordagemDescr" header="Abordagem" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.abordagemDescr }}
                    </template>
                </Column>
                <Column field="competencia" header="Competência" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.competencia }}
                    </template>
                </Column>
                <Column field="cpfResponsavel" header="CPF do Gestor Responsável" :sortable="true">
                    <template #body="slotProps">
                        {{ $toCpfCnpj(slotProps.data.cpfResponsavel) }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import Services from './services';
import BtnInserir from './BtnInserir';
import BtnDetalhar from './BtnDetalhar';
import BtnAtualizar from './BtnAtualizar';
import BtnExcluir from './BtnExcluir';

export default {
    components: {
        BtnInserir,
        BtnDetalhar,
        BtnAtualizar,
        BtnExcluir,
    },

    data() {
        return {
            consultorias: null,
        };
    },

    computed: {
        mostrarListagem() {
            return this.$route.name == 'Utilidades_Consultorias';
        },
    },

    methods: {
        obterConsultorias() {
            this.$store.dispatch('addRequest');
            Services.obterConsultorias().then((response) => {
                if (response?.success) {
                    this.consultorias = response.data;
                } else {
                    this.$store.dispatch('setErros', response.errors);
                    this.consultorias = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizar() {
            this.obterConsultorias();
        },
    },

    created() {
        this.obterConsultorias();
    },

    watch: {
        $route(to) {
            if (to.name === 'Utilidades_Consultorias') {
                if (this.$store.getters.atualizar) {
                    this.obterConsultorias();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
    },
};
</script>