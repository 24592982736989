<template>
    <font-awesome-icon
        icon="trash"
        @click="confirmar()"
        size="lg"
        class="icon-button p-text-danger"
        title="Excluir"
        v-if="$temAcessoView('UTIL-CONSULTORIAS-04') && tipo == 'I'"
    />
    <Button
        label="Excluir"
        icon="pi pi-trash"
        iconPos="left"
        class="p-button-danger"
        v-if="$temAcessoView('UTIL-CONSULTORIAS-04') && tipo == 'B'"
        @click="confirmar()"
    />
</template>

<script>
import Services from './services';

export default {
    props: {
        tipo: {
            type: String,
        },

        consultoria: {
            type: Object,
        },
    },

    emits: ['obterListagemAtualizada'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja excluir a consultoria?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.excluir();
                },
            });
        },

        excluir() {
            this.$store.dispatch('addRequest');
            Services.excluir(this.consultoria.consultoriaId).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Utilidades',
                        detail: 'Consultoria excluída com sucesso',
                        life: 3000,
                    });
                    this.toListagem();
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Metas',
                        detail: response.errors[0],
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toListagem() {
            if (this.tipo == 'B') {
                this.$store.dispatch('setAtualizar', true);
                this.$router.push({
                    name: 'Utilidades_Consultorias',
                });
            } else {
                this.$emit('obterListagemAtualizada');
            }
        },
    },
};
</script>